import { Container, Grid, CircularProgress } from "@material-ui/core"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { useParams } from "react-router-dom"
import { Header, ChatHead } from "../components"
import { fetchProjects, fetchCurrency } from "../services"
import BriefForm from "../components/BriefForm";

function ProjectBrief() {
    const { projectId } = useParams()
    const [duration, setDuration] = useState(null)
    const [currency, setCurrency] = useState({ code: 'usd', symbol: '$', rate: 1 })


    const { mutate: refetchCurrency } = useMutation((currency_id) => fetchCurrency({ id: currency_id }, true), {
        onSuccess: res => {
            setCurrency(res.currencies[0])
        },
        refetchOnWindowFocus: false,
    })

    const { data: project, isLoading, refetch, isFetching } = useQuery("project", () => fetchProjects(projectId), {
        refetchOnWindowFocus: false,
        select: (data) => data.projects[0],
        onSuccess: project => {
            refetchCurrency(project.currency_id)
            if (project.is_duration_parallel) {
                setDuration(project.duration)
            }
        }
    })

    if (isLoading) {
        return (
            <div className='is-flex aic jcc mt-45'>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="content-wrapper mb-55">
            <Container spacing={1}>
                <Grid item lg={12}>
                    <div className="is-flex aic">
                        <h2 className="mb-20 mt-20">Project: {project.name}</h2>
                    </div>
                </Grid>
                <Grid item lg={12}>
                    <div className="is-flex aifs jcsa">
                        <div className="is-flex fdc aifs">
                            <h4 className="mb-5">Owner: {project.owner_name} - {project.owner_email} - {project.company_name}</h4>
                            <div className="is-flex mb-5">
                                <h4 className="mr-5">Goal:</h4>
                                <h4
                                    dangerouslySetInnerHTML={{ __html: project.goal || "N/A" }}></h4>
                            </div>
                            <h4 className="mb-5">Service: {project.service_name}</h4>
                            <h4 className="mb-5">Price: {project.price_formatted}</h4>
                            {project.has_videos ?
                                <>
                                    <h4 className="mb-5">Requested videos: {project.requestedVideos.map((e, i) => (
                                        e.plan_name + (i < project.requestedVideos.length - 1 ? ', ' : '')
                                    ))} </h4>
                                </> : null}
                        </div>

                        <div className="is-flex fdc aifc">
                            <h4 className="mb-5">Content type: {project.brief?.contentType.label}</h4>
                            <h4 className="mb-5">Country: {project.brief?.country.label}</h4>
                            <h4 className="mb-5">Format: {project.brief?.format.label}</h4>
                            <h4 className="mb-5">Instructions: {project.brief?.instructions}</h4>
                            <h4 className="mb-5">Language: {project.brief?.language.label}</h4>
                            <h4 className="mb-5">niche: {project.brief?.niche.label}</h4>
                            <h4 className="mb-5">Platform: {project.brief?.platform.label}</h4>
                            <h4 className="mb-5">Product delivering: {project.brief?.productDelivering.label}</h4>
                            <h4 className="mb-5">Reference: {project.brief?.reference}</h4>
                            <h4 className="mb-5">Tone of voice: {project.brief?.toneOfVoice.label}</h4>
                        </div>
                    </div>
                </Grid>
            </Container>
        </div>

    )
}

export default ProjectBrief