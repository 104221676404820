import React, { useMemo } from "react"
import Select from "react-select"

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, FilterOptions },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = []
    if (FilterOptions) {
      FilterOptions.forEach(option => {
        if (typeof option === 'object' && option !== null) {
          const { label, value } = option
          options.push({ label, value })
        } else {
          options.push({ label: option, value: option })
        }
      })
    }
    else if (preFilteredRows) {
      preFilteredRows.map(e => e.values[id]).flat().forEach(value => {
        let added = options.some((opt) => opt.value == value)
        if (!added)
          options.push({ label: value, value })
      })
    }
    return [...options.values()]
  }, [id, preFilteredRows, FilterOptions])

  // Render a multi-select box
  return (
    <div style={{ width: 180, zIndex: 10000 }}>
      <Select
        value={filterValue ? options.find(o => o.value === filterValue) : null}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        onChange={({ value }) => {
          setFilter(value || undefined); console.log(id, value); sessionStorage.setItem(id, value);
        }}
        options={options}
      />
    </div>
  )
}

export default SelectColumnFilter