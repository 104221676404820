import React, { useMemo } from "react"
import { useMutation, useQuery } from "react-query"
import { Grid, Container } from "@material-ui/core"
import TableContainerServerSide from "../components/TableContainer"
import DefaultFilter from "../components/DefaultFilter"
import { useAuth } from "../utils/Auth";
import { deleteCoupon, fetchAllCoupons, updateCoupon } from "../services/coupon";
import { Chip } from "@mui/material";
import moment from "moment";
import SelectColumnFilter from "../components/SelectFilter";

function Coupons() {
  const { user } = useAuth()

  const { data: coupons = [], isLoading, refetch } = useQuery("coupons", () => fetchAllCoupons(), {
    select: res => res.coupons
  })

  const { mutate: removeCoupon, isLoading: isDeleting } = useMutation(id => deleteCoupon(id), {
    onSuccess: () => {
      alert("Coupon deleted!")
      refetch()
    },
    onError: error => {
      alert(error.data?.message)
    },
  })

  const { mutate: updateCouponActive, isLoading: isUpdating } = useMutation(({ id, is_active }) => updateCoupon(id, { is_active }), {
    onSuccess: () => {
      alert("Coupon updated!")
      refetch()
    },
    onError: error => {
      alert(error.data?.message)
    },
  })

  const columns = useMemo(() => [
    {
      accessor: 'promo_code',
      Header: 'Promo Code',
      Filter: DefaultFilter,
      disableSortBy: true,
      show: true,
    },
    {
      accessor: 'name',
      Header: 'Coupon Name',
      Filter: DefaultFilter,
      disableSortBy: true,
      show: true,
    },
    {
      accessor: 'percent_off',
      Header: 'Discount Percent',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { percent_off } = row.original
        return percent_off > 0 ? <span>{percent_off}%</span> : null
      }
    },
    {
      accessor: 'max_amount',
      Header: 'Max discount amount',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { max_amount, max_amount_formatted } = row.original
        return max_amount > 0 ? <span>{max_amount_formatted}</span> : null
      }
    },
    {
      accessor: 'amount_off',
      Header: 'Discount Amount',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { amount_off, amount_off_formatted } = row.original
        return amount_off > 0 ? <span>{amount_off_formatted}</span> : null
      }
    },
    {
      accessor: 'is_active_str',
      Header: 'Active?',
      Filter: SelectColumnFilter,
      FilterOptions: ['Yes', 'No'],
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { is_active } = row.original
        return <Chip size="small" className={is_active ? 'chip-green' : 'chip-red'} label={is_active ? 'Yes' : 'No'} />
      }
    },
    {
      accessor: 'max_redemptions',
      Header: 'Redeems',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { max_redemptions, redemptions, is_redeemable } = row.original
        return <Chip size="small" className={redemptions === 0 ? 'chip-gray' : is_redeemable ? 'chip-green' : 'chip-red'} label={`${redemptions}/${max_redemptions}`} />
      }
    },
    {
      accessor: 'date_expire',
      Header: 'Expiry Date',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { date_expire, is_expired } = row.original
        return date_expire ? <Chip size="small" className={is_expired ? 'chip-red' : 'chip-green'} label={moment(date_expire).format('ll')} /> : null
      }
    },
    {
      accessor: 'is_valid_str',
      Header: 'Valid?',
      Filter: SelectColumnFilter,
      FilterOptions: ['Yes', 'No'],
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { is_valid } = row.original
        return <Chip size="small" className={is_valid ? 'chip-green' : 'chip-red'} label={is_valid ? 'Yes' : 'No'} />
      }
    },
    {
      accessor: 'max_redemptions_per_user',
      Header: 'Max Redeems per user',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { max_redemptions_per_user } = row.original
        return <span>{max_redemptions_per_user === 1 ? 'Once' : max_redemptions_per_user}</span>
      }
    },
    {
      accessor: 'customer_name',
      Header: 'Specific Customer',
      disableFilters: true,
      disableSortBy: true,
      show: true,
      Cell: ({ row }) => {
        const { customer_name } = row.original
        return <span>{customer_name}</span>
      }
    },
    {
      Header: "Actions",
      Filter: ``,
      Cell: ({ cell }) => {
        let { id, is_active, redemptions } = cell.row.original
        return (
          <div className="is-flex aic">
            {user.role_id == process.env.REACT_APP_ADMIN_ROLE || user.role_id == process.env.REACT_APP_ASSISTANT_ADMIN_ROLE ?
              <>
                {is_active
                  ? <button className="stllr-btn btn-sm danger bordered" disabled={isUpdating} onClick={() => updateCouponActive({ id, is_active: false })}>deactivate</button>
                  : <button className="stllr-btn btn-sm secondary bordered" disabled={isUpdating} onClick={() => updateCouponActive({ id, is_active: true })}>activate</button>
                }
                {redemptions === 0
                  ? <button className="stllr-btn btn-sm danger ml-10" disabled={isDeleting} onClick={() => removeCoupon(id)}>Delete</button>
                  : null
                }
              </>
              : null}
          </div>
        )
      }
    }
  ]);

  return (
    <Container spacing={1} maxWidth='xl'>
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Coupons (<i>{coupons.length}</i>)</h2>
          <div className="is-flex aic">
            {user?.email != process.env.REACT_APP_FINANCE_ADMIN_EMAIL && <a className="stllr-btn primary btn-md" href="/new-coupon">Create coupon</a>}
          </div>
        </div>

        {isLoading ? "loading.." : <TableContainerServerSide
          name='coupons'
          paging={true}
          columns={columns}
          hiddenColumns={[]}
          data={coupons}
          hasFilters={true}
          allowSorting={true}
        />}
      </Grid>

    </Container>
  )
}

export default Coupons