import React, { useCallback, useMemo, useRef, useState } from "react"
import { Container, Grid, Chip } from "@material-ui/core"
import { useHistory } from "react-router";
import { DataGrid } from '@material-ui/data-grid';
import { useMutation, useQuery } from "react-query";
import { fetchAllExperts, chatWithExpert, fetchServices } from "../services"
import TableContainerServerSide from "../components/TableContainerServerSide"
import Select from "react-select"
import { Button } from "../components/button"
import { OpenInNew, StarOutline } from '@material-ui/icons';
import StarFilled from '@material-ui/icons/Star'
import { Rating } from '@mui/material'
import SelectColumnFilter from "../components/SelectFilter"
import DefaultFilter from "../components/DefaultFilter"
import moment from 'moment';

function Ugc() {
    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [experts, setExperts] = useState([])

    const request = useRef()

    const { data: serviceOptions } = useQuery('services', () => fetchServices(), {
        select: res => res.services.map(e => e.name),
        refetchOnWindowFocus: false,
    })

    const columns = useMemo(() => [
        {
            Header: 'Name',
            id: 'name',
            accessor: ({ name, signin_method }) => {
                return `${name}${signin_method === 'google'
                    ? ' (G)'
                    : signin_method === 'linkedin'
                        ? ' (L)'
                        : ''
                    }`
            },
            // Filter: DefaultFilter,
            // show: false
            disableFilters: true
        },
        {
            Header: 'Email',
            accessor: `email`,
            // Filter: DefaultFilter,
            // show: false
            disableFilters: true
        },
        {
            Header: 'Phone number',
            accessor: `phone_number`,
            // Filter: DefaultFilter,
            // show: false
            disableFilters: true
        },
        {
            Header: 'Signed up on',
            id: 'date_add',
            accessor: ({ date_add }) => {
                return moment(date_add).format('LL')
            },
            disableFilters: true
        },
        // {
        //     Header: 'Services',
        //     id: 'services',
        //     accessor: ({ services }) => {
        //         return services.map(s => {
        //             return s.service_name
        //         })
        //     },
        //     Cell: ({ row }) => {
        //         let { services } = row.original

        //         return services.map(as => {
        //             return <div className="service-chip">{as.service_name}</div>
        //         })
        //     },
        //     disableFilters: true
        // },
        {
            Header: 'Language',
            id: 'languages',
            accessor: ({ languages }) => {
                return languages.map(i => {
                    return i.name
                })
            },
            Cell: ({ row }) => {
                let { languages } = row.original

                return languages.map(as => {
                    return <div className="service-chip">{as.name}</div>
                })
            },
            Filter: DefaultFilter,
            minWidth: 700,
        },
        // {
        //     Header: 'Industries',
        //     id: 'industries',
        //     accessor: ({ industries }) => {
        //         return industries.map(i => {
        //             return i.name
        //         })
        //     },
        //     Cell: ({ row }) => {
        //         let { industries } = row.original

        //         return industries.map(as => {
        //             return <div className="service-chip">{as.name}</div>
        //         })
        //     },
        //     disableFilters: true
        // },
        // {
        //     Header: 'Sectors',
        //     id: 'sectors',
        //     accessor: ({ sectors }) => {
        //         return sectors.map(i => {
        //             return i.name
        //         })
        //     },
        //     Cell: ({ row }) => {
        //         let { sectors } = row.original

        //         return sectors.map(as => {
        //             return <div className="service-chip">{as.name}</div>
        //         })
        //     },
        //     disableFilters: true
        // },
        {
            Header: 'Country',
            id: 'country',
            accessor: 'country_name',
            Filter: DefaultFilter,
        },
        {
            Header: 'City',
            id: 'city',
            accessor: 'city_name',
            Filter: DefaultFilter,
        },
        {
            Header: 'Show Face',
            accessor: 'is_show_face_str',
            Filter: SelectColumnFilter,
            FilterOptions: ['Yes', 'No'],
            Cell: ({ cell }) => {
                return !cell.value ? 'N/A' : <Chip size="small" className={`chip-${cell.value.toLowerCase() === "yes" ? `green` : `red`}`} label={cell.value.toLowerCase() === "yes" ? `Yes` : `No`} />
            }
        },
        {
            Header: 'Gender',
            id: 'gender',
            accessor: 'gender',
            Filter: SelectColumnFilter,
            FilterOptions: [{ label: 'Male', value: 'm' }, { label: 'Female', value: 'f' }],
        },
        {
            Header: 'Job Status',
            id: 'job_status',
            accessor: 'job_status',
            disableFilters: true,
        },
        {
            Header: `Rating`,
            // disableFilters: true,
            accessor: `rating_sort`,
            Cell: ({ row }) => {
                const { avg_rating, n_reviews } = row.original;
                return (
                    <div className="is-flex fdr">
                        <Rating
                            // name="communication"
                            defaultValue={avg_rating}
                            icon={<StarFilled style={{ color: '#fcbb08' }} />}
                            emptyIcon={<StarOutline style={{ color: '#f2b407' }} />}
                            // onChange={(e, value) => setFieldValue(name, value)}
                            // onChangeActive={(e, v) => setHover(v >= 0 ? v : null)}
                            max={5}
                            readOnly={true}
                        />

                        <div>({n_reviews})</div>
                    </div>
                )
            },
            disableFilters: true
        },
        {
            Header: `Price/hour`,
            accessor: `hourly_rate`,
            Cell: ({ row }) => {
                const { priceRange, hourly_rate_formatted } = row.original;
                return <span>{hourly_rate_formatted}</span>
            },
            disableFilters: true
        },
        {
            Header: 'Pulses score',
            accessor: `total_pulses_score`,
            disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableFilters: true,
            Cell: ({ row }) => {

                const values = row.original

                let statusChipColor = ``
                switch (Number(values.status_id)) {
                    case Number(process.env.REACT_APP_EXPERT_PENDING_STATUS):
                        statusChipColor = `-orange`
                        break;

                    case Number(process.env.REACT_APP_EXPERT_ACCEPTED_STATUS):
                        statusChipColor = `-green`
                        break;

                    case Number(process.env.REACT_APP_EXPERT_REJECTED_STATUS):
                        statusChipColor = `-red`
                        break;

                    case Number(process.env.REACT_APP_EXPERT_PENDING_DOCUMENTS_STATUS):
                        statusChipColor = `-blue`
                        break;

                    default:
                        statusChipColor = `-grey`
                }

                return <Chip size="small" className={`chip${statusChipColor}`} label={values.status} />
            }
        },
        {
            Header: 'Available',
            accessor: 'is_available_str',
            disableFilters: true,
            Cell: ({ cell }) => {
                return !cell.value ? 'N/A' : <Chip size="small" className={`chip-${cell.value.toLowerCase() === "yes" ? `green` : `red`}`} label={cell.value.toLowerCase() === "yes" ? `Yes` : `No`} />
            }
        },
        {
            Header: "Actions",
            Filter: ``,
            Cell: ({ cell }) => {
                let { id, uid } = cell.row.original
                return (
                    <div className="is-flex aic">
                        {/* <Button className="stllr-btn btn-sm primary bordered mr-5" text="DETAILS" /> */}
                        <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc" style={{ padding: "0px 8px" }} href={`/edit-expert/${id}`}>EDIT</a>
                        {/* <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/chat-expert/${id}`}>CHAT</a> */}
                        <Button className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" text="Chat" style={{ padding: "0px 8px" }} disabled={isStartingchat} onClick={() => startChat(id)} />
                        <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/score-expert/${id}`}>SCORE</a>
                        <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} target="_blank" href={`${process.env.REACT_APP_STLLR_URL}profile/${uid}`}>VIEW PROFILE</a>
                        <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/application/${id}`}>VIEW APPLICATION</a>
                        <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc ml-10" style={{ padding: "0px 8px" }} href={`/warnings/${id}`}>Warnings</a>
                    </div>
                )
            }
        }
    ], [serviceOptions])

    const { mutate: fetch, isLoading } = useMutation(params => fetchAllExperts(null, params), {
        onSuccess: ({ experts }) => {
            setExperts(experts.data)
            setTotal(experts.total)
            if (experts.pageCount !== pageCount) {
                setPageCount(experts.pageCount)
            }
        }
    })

    const { mutate: startChat, isLoading: isStartingchat } = useMutation(expertId => chatWithExpert({ expertId }), {
        onSuccess: data => {
            console.log(data)
            if (data && !isStartingchat)
                window.location.href = `/chats-admin/${data.chat_uid}`
        },
        onError: error => {
            alert(error.data?.message, 'error')
        }
    })

    const handleRefetchData = useCallback(
        ({ pageIndex, pageSize, search }) => {
            fetch({
                pageIndex,
                pageSize,
                search: { services: 'User generated content', date_add_sort: 'DESC', ...search },
            })
        },
        []
    )

    return (
        <Container spacing={1} maxWidth='xl'>
            <Grid item lg={12}>
                <div className="is-flex aic jcsb">
                    <h2 className="mt-0 mb-5 mt-20">UGC Experts (<i>{total}</i>)</h2>
                </div>

                <TableContainerServerSide
                    name='experts'
                    paging={true}
                    columns={columns}
                    hiddenColumns={[]}
                    data={experts}
                    hasFilters={true}
                    loading={isLoading}
                    pageCount={pageCount}
                    fetchData={handleRefetchData}
                />
            </Grid>
        </Container>
    )
}

export default Ugc