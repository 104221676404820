import React, { useMemo } from "react"
import { Container, Grid, Chip } from "@material-ui/core"
import { useHistory } from "react-router";
import { useQuery } from "react-query";
import { fetchCustomers, fetchUser, updateUserRestApi } from "../services"
import TableContainer from "../components/TableContainer"
import DefaultFilter from "../components/DefaultFilter"
import moment from "moment";
import { useAuth } from "../utils/Auth";
import { useMutation } from 'react-query';

function Companies() {
  const { user } = useAuth()

  // const { data: user, isLoading: userLoading } = useQuery("user", fetchUser, {
  //   select: (data) => data.data,
  // })

  const columns = useMemo(() => [
    {
      Header: 'Company name',
      accessor: `company_name`,
      Filter: DefaultFilter,
      disableSortBy: true,
      show: false
    },
    {
      Header: 'Owner name',
      accessor: ({ name, signin_method }) => {
        return `${name}${signin_method === 'google'
          ? ' (G)'
          : signin_method === 'linkedin'
            ? ' (L)'
            : ''
          }`
      },
      Filter: DefaultFilter,
      // disableSortBy: true,
      show: false
    },
    {
      Header: 'Email',
      accessor: `email`,
      Filter: DefaultFilter,
      // disableSortBy: true,
      show: false
    },
    {
      Header: 'Briefs done',
      accessor: `briefs_filled`,
      Filter: DefaultFilter,
      isSortable: true,
      sortType: 'number',
      show: false
    },
    {
      Header: 'Phone number',
      accessor: `phone_number`,
      Filter: DefaultFilter,
      // disableSortBy: true,
      show: false
    },
    {
      Header: 'Sign-Up date',
      accessor: 'date_add',
      disableFilters: true,
      isSortable: true,
      sortType: (rowA, rowB) => new Date(rowA.original.date_add) - new Date(rowB.original.date_add),
      Cell: ({ cell }) => {
        let { date_add } = cell.row.original
        return moment(date_add).format('ll')
      }
    },
    {
      Header: "Actions",
      Filter: ``,
      Cell: ({ cell }) => {
        let { id } = cell.row.original
        return (
          <div className="is-flex aic">
            {user.role_id == process.env.REACT_APP_ADMIN_ROLE || user.role_id == process.env.REACT_APP_ASSISTANT_ADMIN_ROLE ?
              <>
                <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc mr-10" style={{ padding: "0px 8px" }} href={`/company-brief/${id}`}>BRIEF</a>
                <a className="stllr-btn btn-sm secondary bordered is-flex aic jcc" style={{ padding: "0px 8px" }} onClick={() => updateUserRestApiAllowance({ id, is_api: cell.row.original.is_api == 1 ? 0 : 1 })}>{cell.row.original.is_api == 1 ? 'Disallow API' : 'Allow API'}</a>
              </>
              : null}
          </div>
        )
      }
    }
  ])

  const { data: companies, isLoading, refetch } = useQuery("companies", () => fetchCustomers(), {
    select: res => res.customers
  })

  const { mutate: updateUserRestApiAllowance, isFetching: isUpdating } = useMutation(data => updateUserRestApi(data), {
    onSuccess: () => {
      alert("State is updated successfully")
      refetch()
    },
    onError: e => {
      alert(e.data?.message)
      console.log(e)
    }
  })

  if (isLoading) return <div>Loading...</div>

  return (
    <Container spacing={1} maxWidth='lg'>
      <Grid item lg={12}>
        <div className="is-flex aic jcsb">
          <h2 className="mt-0 mb-5 mt-20">Companies (<i>{companies.length}</i>)</h2>
        </div>
        <TableContainer paging={true} columns={columns} hiddenColumns={[]} data={companies} hasFilters={true} allowSorting={true} />
      </Grid>
    </Container>
  )
}

export default Companies