import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const createProject = async (data) => {
    return request({
        url: `${BaseUrl}v1/project`,
        method: "POST",
        data
    }, true)
}

export const fetchProjects = async (id=null, withChat = false, order_by_last_message, is_enterprise, query) => {
    let params = {}
    if (id) params.id = id
    if (withChat) params.has_group_chat = 1 
    if (order_by_last_message) params.order_by_last_message = 1
    if (is_enterprise) params.is_enterprise = 1
    params = { ...params, ...query }
    return request({
        url: `${BaseUrl}v1/projects`,
        params
    }, true)
}

export const fetchProject = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/${projectId}`,
    }, true)
}

export const updateProject = async (data, projectId) => {
    return request({
        url: `${BaseUrl}v1/project/${projectId}`,
        method: "PUT",
        data
    }, true)
}

export const markAsFinished = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/close/${projectId}`,
        method: "POST"
    }, true)
}

export const initStripeSubscription = async (data) => {
    return request({
        url: `${BaseUrl}v1/subscription/init`,
        method: "POST",
        data,
        params: { currency_id: data.currency_id }
    }, true)
}

export const initStripeReplacePayment = async (data) => {
    return request({
        url: `${BaseUrl}v1/replace_payment/init`,
        method: "POST",
        data,
    }, true)
}

export const initStripePayment = async (data) => {
    return request({
        url: `${BaseUrl}v1/payment/init`,
        method: "POST",
        data,
        params: { currency_id: data.currency_id }
    }, true)
}

export const initStripBriefPayment = async (data) => {
    return request({
        url: `${BaseUrl}v1/payment/brief/init`,
        method: "POST",
        data,
        params: { currency_id: data.currency_id }
    }, true)
}

export const fetchProjectServices = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/services/${projectId}`,
        method: "GET"
    }, true)
}

export const fetchProjectMilestones = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/milestones/${projectId}`
    }, true)
}

export const fetchProjectHistory = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/history/${projectId}`
    }, true)
}

export const deleteService = async (project_service_id, project_id) => {
    return request({
        url: `${BaseUrl}v1/project/services/${project_id}`,
        method: "DELETE",
        data: {
            project_service_id
        }
    }, true)
}

export const deleteMilestone = async (project_milestone_id, project_id) => {
    return request({
        url: `${BaseUrl}v1/project/milestones`,
        method: "DELETE",
        data: {
            project_id,
            project_milestone_id
        }
    }, true)
}

export const fetchProjectStatus = async () => {
    return request({
        url: `${BaseUrl}v1/project_status`,
    }, true)
}

export const pauseStripeRenewal = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/project/pause/${projectId}`,
        method: "POST"
    }, true)
}

export const releaseTransaction = async (transaction_id) => {
    return request({
        url: `${BaseUrl}v1/transaction/${transaction_id}`,
        method: "PUT"
    }, true)
}

export const fetchPulses = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/pulse/${projectId}`,
        method: "GET"
    }, true)
}

export const createPulse = async (data, projectId) => {
    return request({
        url: `${BaseUrl}v1/pulse/${projectId}`,
        method: "POST",
        data
    }, true)
}

export const fetchProjectBillings = async (projectId) => {
    return request({
        url: `${BaseUrl}v1/billings`,
        method: "GET",
        params: { project_id: projectId }
    }, true)
}

export const updateProjectBillingById = async ({ id: billingId, ...data }) => {
    return request({
        url: `${BaseUrl}v1/billings/${billingId}`,
        method: "PUT",
        data
    }, true)
}

export const fetchAccountantProjects = async (id=null) => {
    let qs = id ? `id=${id}&` : ``
    return request({
        url: `${BaseUrl}v1/accountant-projects?${qs}`,
    }, true)
}

export const fetchMarketeerProjects = async (params) => {
    return request({
        url: `${BaseUrl}v1/marketeer-projects`,
        params
    }, true)
}

export const fetchPostOfferProjects = async (params) => {
    return request({
        url: `${BaseUrl}v1/projects/post-offer`,
        params
    }, true)
}

export const getProjectContractPDF = async (params) => {
    return request({
        url: `${BaseUrl}v1/project-contract`,
        responseType: 'arraybuffer',
        params
    }, true)
}

export const generateMilestones = async (data) => {
    return request({
        url: `${BaseUrl}v1/ai/milestones`,
        method: "POST",
        data
    }, true)
}

export const generateProposalMilestones = async (data) => {
    return request({
        url: `${BaseUrl}v1/ai/proposal/milestones`,
        method: "POST",
        data
    }, true)
}

export const generateProposal = async (data) => {
    return request({
        url: `${BaseUrl}v1/ai/proposal`,
        method: "POST",
        data
    }, true)
}

export const getOffers = async ({ offset, limit, is_post }) => {
    return request({
        url: `${BaseUrl}v1/offers`,
        method: "GET",
        params: { offset, limit, is_post }
    }, true)
}

export const fetchVideoTypes = async ({ currency_id }) => {
    return request({
        url: `${BaseUrl}v1/plans`,
        method: "GET",
        params: { currency_id }
    })
}

export const fetchProductDelivering = async () => {
    return request({
        url: `${BaseUrl}v1/product-delivering`,
        method: "GET",
    })
}

export const fetchToneOfVoices = async () => {
    return request({
        url: `${BaseUrl}v1/tones`,
        method: "GET",
    })
}

export const fetchCountries = async () => {
    return request({
        url: `${BaseUrl}v1/countries`,
        method: "GET",
    })
}

export const fetchCityByCountry = async (countryId) => {
    return request({
        url: `${BaseUrl}v1/cities/${countryId}`,
        method: "GET",
    })
}

export const fetchLanguages = async () => {
    return request({
        url: `${BaseUrl}v1/languages`,
        method: "GET",
    })
}

export const fetchContentTypes = async () => {
    return request({
        url: `${BaseUrl}v1/content-types`,
        method: "GET",
    })
}

export const fetchVideoFormats = async () => {
    return request({
        url: `${BaseUrl}v1/video-formats`,
        method: "GET",
    })
}

export const fetchPlatforms = async () => {
    return request({
        url: `${BaseUrl}v1/platforms`,
        method: "GET",
    })
}

export const fetchNiches = async () => {
    return request({
        url: `${BaseUrl}v1/niches`,
        method: "GET",
    })
}

export const fetchBriefRecommendations = async (id) => {
    return request({
        url: `${BaseUrl}v1/brief/${id}/recommendations`,
        method: "GET",
    }, true)
}

export const addBriefRecommendations = async (id, data) => {
    return request({
        url: `${BaseUrl}v1/brief/${id}/recommendations`,
        method: "POST",
        data,
    }, true)
}

export const removeBriefRecommendations = async (id, data) => {
    return request({
        url: `${BaseUrl}v1/brief/${id}/recommendations`,
        method: "DELETE",
        data,
    }, true)
}

export const fetchProjectVideos = async (id) => {
    return request({
        url: `${BaseUrl}v1/project/${id}/videos`,
        method: "GET",
    }, true)
}

export const addProjectVideoDetails = async (data, videoId) => {
    return request({
        url: `${BaseUrl}v1/video/${videoId}/brief`,
        method: "PUT",
        data
    }, true)
}

export const submitVideoDetails = async (data, videoId) => {
    return request({
        url: `${BaseUrl}v1/video/${videoId}/submit`,
        method: "POST",
        data
    }, true)
}

export const addVideoAssigne = async (id, data) => {
    return request({
        url: `${BaseUrl}v1/video/${id}/assignee`,
        method: "POST",
        data,
    }, true)
}

export const removeVideoAssigne = async (id) => {
    return request({
        url: `${BaseUrl}v1/video/${id}/assignee`,
        method: "DELETE",
    }, true)
}

