import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_STLLR_API_URL

export const login = async (data) => {
    return request({
        url: `${process.env.REACT_APP_STLLR_API_URL}v1/login`,
        method: "POST",
        data
    })
}

export const signup = async (data) => {
    return request({
        url: `${process.env.REACT_APP_STLLR_API_URL}v1/signup`,
        method: "POST",
        data
    })
}

export const fetchCustomers = async () => {
    return request({
        url: `${BaseUrl}v1/customers`
    }, true)
}

export const fetchUser = async () => {
    return request({
        url: `${BaseUrl}v1/me`
    }, true)
}

export const fetchBriefBlocks = async (companyId) => {
    return request({
        url: `${BaseUrl}v1/user/briefs`,
        params: { user_id: companyId }
    }, true)
}

export const submitBriefBlock = async (brief_block_id, answers, companyId) => {
    return request({
        url: `${BaseUrl}v1/user/briefs/${brief_block_id}`,
        method: 'POST',
        data: { answers, user_id: companyId }
    }, true)
}

export const updateBriefBlock = async (brief_block_id, answers, companyId) => {
    return request({
        url: `${BaseUrl}v1/user/briefs/${brief_block_id}`,
        method: 'PUT',
        data: { answers, user_id: companyId }
    }, true)
}

export const fetchBriefBlock = async (brief_block_id, companyId) => {
    return request({
        url: `${BaseUrl}v1/user/briefs/${brief_block_id}`,
        params: { user_id: companyId }
    }, true)
}

export const fetchPageActivities = async (query) => {
    let params = {}
    params = { ...params, ...query }
    return request({
        url: `${BaseUrl}v1/activity`,
        params
    }, true)
}

export const fetchDemoRequests = async () => {
    return request({
        url: `${BaseUrl}v1/get_demo_requests`,
    }, true)
}

export const fetchScrapped = async (filters) => {
    let qs = `${filters}` || ``
    return request({
        url: `${BaseUrl}v1/scrapped_services${qs}`
    }, true)
}

export const fetchAdmins = async () => {
    return request({
        url: `${BaseUrl}v1/admins`
    }, true)
}

export const fetchServiceAvgPrice = async (data) => {
    return request({
        url: `${BaseUrl}v1/service_avg_rate`,
        method: 'GET',
        params: { service_id: data }
    }, true)
}

export const updateUserRestApi = async (data) => {

    return request({
        url: `${BaseUrl}v1/user_flag/${data.id}`,
        method: 'PUT',
        data: { is_api: data.is_api }
    }, true)
}