import React, { useState, useRef } from "react"
import { useParams } from "react-router-dom"
import * as Yup from "yup"
import { useQuery, useMutation } from "react-query"
import { Button } from "../../components/button";
import { Formik } from "formik"
import { Container, Grid } from '@material-ui/core';
import { fetchExperts, fetchCustomers, fetchCurrenceis, fetchVideoTypes, fetchPlatforms, fetchNiches, fetchProductDelivering, fetchToneOfVoices, fetchCountries, fetchLanguages, fetchContentTypes, fetchVideoFormats, fetchProjects, fetchProjectVideos, fetchProjectStatus, updateProject, addVideoAssigne, removeVideoAssigne } from "../../services"
import Select from "react-select"
import BriefRecommendations from "../../components/BriefRecommendations";
import { PayButton } from "../../components/PayButton";
import { Editor } from '@tinymce/tinymce-react'
import { FillVideoDetails } from "../../components/FillVideoDetails";
import VideoRecommendationsModal from "../../components/VideoRecommendationsModal";
import { ViewVideoDetails } from "../../components/ViewVideoDetails";

function EditUGCProject() {
  const { projectId } = useParams()
  const formikRef = useRef(null)
  const [videoDetailsOpen, setVideoDetailsOpen] = useState(null)
  const [viewVideoDetailsOpen, setViewVideoDetailsOpen] = useState(null)
  const [videoRecoOpen, setVideoRecoOpen] = useState(null)
  const [expertOptions, setExpertOptions] = useState([])
  const [typeOptions, setTypeOptions] = useState([])
  const genders = [
    { value: 'm', label: 'Male' },
    { value: 'f', label: 'Female' },
  ]

  const ages = [
    { value: 'Less 18 years', label: 'Less 18 years' },
    { value: '18-30 years', label: '18-30 years' },
    { value: 'More than 30 years', label: 'More than 30' },
  ]

  const [editMode, setEditMode] = useState(null)

  const places = [
    { value: 'in', label: 'Indoor' },
    { value: 'out', label: 'Outdoor' },
  ]

  const durations = [
    { value: 'Up to 15 sec', label: 'Up to 15 sec' },
    { value: '15 - 30 Sec', label: '15 - 30 Sec' },
    { value: '30 - 60 Sec', label: '30 - 60 Sec' },
    { value: 'Other', label: 'Other' },
  ]

  const emptyVideoObject = {
    expert_id: null,
    type_id: null,
  }

  const { data: project, isLoading, refetch, isFetching } = useQuery("project", () => fetchProjects(projectId), {
    refetchOnWindowFocus: false,
    select: (data) => data.projects[0],
    // onSuccess: project => {
    // }
  })

  const { data: videos = [], isLoading: isVideosLoading, refetch: refetchVideos } = useQuery("videos", () => fetchProjectVideos(projectId), {
    refetchOnWindowFocus: true,
    select: (data) => data.videos,
  })

  const { data: currencyOptions = [], isLoading: isCurrenciesLoading } = useQuery(['currencies'], () => fetchCurrenceis(), {
    select: res => res.currencies?.map(e => ({ label: `${e.name} - ${e.code}`, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const paymentMethods = [
    {
      label: 'Stripe',
      value: process.env.REACT_APP_PAYMENT_METHOD_STRIPE,
    },
    {
      label: 'Bank Transfer',
      value: process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER,
    },
  ]

  const { data: experts = [], isLoading: isExpertsLoading } = useQuery(["experts"], () => fetchExperts(165, 3), {
    select: (data) => data.experts,
    onSuccess: experts => {
      console.log('experts', experts[0])
      setExpertOptions(experts.map(e => ({ label: `${e.name} - ${e.email} ${e.avg_rating ? `- AvgRating: ${Math.round(e.avg_rating)}` : ''}`, value: e.expert_id })))
    },
    refetchOnWindowFocus: false,
  })

  const { isLoading: isCustomersLoading, data: customers } = useQuery("customers", fetchCustomers, {
    select: (data) => data.customers.map(c => {
      return { label: `${c.name} - ${c.email}`, value: c.id }
    }),
    refetchOnWindowFocus: false,
  })

  const { data: videoTypes = [], isLoading: isVideoTypesLoading } = useQuery(["video-types"], () => fetchVideoTypes({ currency_id: 3 }), {
    select: (data) => data.plans,
    onSuccess: types => {
      setTypeOptions(types.map(e => ({ label: e.name, value: e.id })))
    },
    refetchOnWindowFocus: false,
  })

  const { data: projectStatusOptions, isLoading: projectStatusLoading } = useQuery("project_status", () => fetchProjectStatus(), {
    refetchOnWindowFocus: false,
    select: (data) => data.status.map(s => {
      return { label: s.name, value: s.id }
    })
  })

  const { data: platforms = [], isLoading: isPlatformsLoading } = useQuery(["platforms"], () => fetchPlatforms(), {
    select: (data) => data.platforms.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: niches = [], isLoading: isNichesLoading } = useQuery(["niches"], () => fetchNiches(), {
    select: (data) => data.niches.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: delivering = [], isLoading: isDeliveringLoading } = useQuery(["delivering"], () => fetchProductDelivering(), {
    select: (data) => data.proudct_delivering.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: tones = [], isLoading: isTonesLoading } = useQuery(["tones"], () => fetchToneOfVoices(), {
    select: (data) => data.tones.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: countries = [], isLoading: isCountriesLoading } = useQuery(["countries"], () => fetchCountries(), {
    select: (data) => data.countries.map(e => ({ label: e.name, value: e.id })),
    refetchOnWindowFocus: false,
  })

  const { data: languages = [], isLoading: isLanguagesLoading } = useQuery(["languages"], () => fetchLanguages(), {
    select: (data) => data.languages.map(e => ({ label: e.name, value: e.id, code: e.code })),
    refetchOnWindowFocus: false,
  })

  const { data: contentTypes = [], isLoading: isContentTypeLoading } = useQuery(["content-types"], () => fetchContentTypes(), {
    select: (data) => data.content_types.map(e => ({ label: e.name, value: e.id, slug: e.slug })),
    refetchOnWindowFocus: false,
  })

  const { data: formats = [], isLoading: isFormatsLoading } = useQuery(["video-formats"], () => fetchVideoFormats(), {
    select: (data) => data.video_formats.map(e => ({ label: e.name, value: e.id, slug: e.slug, ratio: e.ratio })),
    refetchOnWindowFocus: false,
  })

  const mutation = useMutation(updatedProject => updateProject(updatedProject, projectId), {
    onSuccess: () => {
      alert("Project successfully updated !")
      refetch()
    },
    onError: (error) => {
      alert(error.data?.message)
    }
  })

  const constcutBriefJSON = (data) => {
    const { platform, niche, product_delivery, country, language, tone, content_type, video_format, instructions, reference } = data
    const brief = {}

    const insertItem = (key, value, source) => {
      const item = source.find(e => e.value === value)
      if (item) {
        brief[key] = {
          value: item.value,
          label: item.label,
        }
      }
    }

    // insertItem('platform', platform, platforms)
    insertItem('niche', niche, niches)
    insertItem('product_delivering', product_delivery, delivering)
    insertItem('country', country, countries)
    // insertItem('language', language, languages)
    // insertItem('toneOfVoice', tone, tones)
    // insertItem('contentType', content_type, contentTypes)
    // insertItem('format', video_format, formats)

    brief.instructions = instructions
    brief.reference = reference

    return JSON.stringify(brief)
  }

  const deconstructBriefValues = (brief) => {
    const { platform, niche, product_delivering, country, language, toneOfVoice, contentType, format, instructions, reference } = brief
    const data = {
      // platform: platform?.value || null,
      niche: niche?.value || null,
      product_delivery: product_delivering?.value || null,
      country: country?.value || null,
      // language: language?.value || null,
      // tone: toneOfVoice?.value || null,
      // content_type: contentType?.value || null,
      // video_format: format?.value || null,
      // instructions: instructions || null,
      // reference: reference || null,
    }

    return data
  }

  const calculatePrice = (video, formatted = true) => {
    const type = videoTypes.find(e => e.id === video.type_id)
    if (type) {
      return formatted ? type.price_formatted : type.price_converted
    }
    return null
  }

  const calculatePayout = (video, index, videos = [], formatted = true) => {
    const type = videoTypes.find(e => e.id === video.type_id)
    if (type) {
      if (videos.some((e, i) => e.expert_id && e.expert_id === video.expert_id && i < index)) {
        return formatted ? type.softened_earning_formatted : type.softened_earning_converted
      }
      return formatted ? type.earning_formatted : type.earning_converted
    }
    return null
  }

  const { mutate: assignVideo, isAssigning } = useMutation(({ video_id, ...data }) => addVideoAssigne(video_id, data), {
    onSuccess: () => {
      alert("Creator assigned successfully")
      refetchVideos()
      setEditMode(null)
    },
    onError: error => {
      alert(error.data?.message)
    },
  })

  const { mutate: unassignVideo, isUnassigning } = useMutation(video_id => removeVideoAssigne(video_id), {
    onSuccess: () => {
      alert("Creator un-assigned successfully")
      refetchVideos()
    },
    onError: error => {
      alert(error.data?.message)
    },
  })

  const handleOnAssign = (video_id, expert_id) => {
    console.log({ video_id, expert_id })
    assignVideo({ video_id, expert_id })
  }

  const handleOnUnassign = (video_id, video) => {
    console.log({ video_id })
    if (video.is_approved) {
      alert("You can't un-assign creator, the video is already approved!")
    }
    else if (video.file) {
      if (window.confirm("Are you sure you want to procees? creator have video uploaded!")) {
        unassignVideo(video_id)
      }
    }
    else if (video.script) {
      if (window.confirm("Are you sure you want to procees? creator have script submitted!")) {
        unassignVideo(video_id)
      }
    }
    else {
      if (window.confirm("Are you sure you want to un-assign creator from this video?")) {
        unassignVideo(video_id)
      }
    }
  }

  const handleOnVideoFormSubmit = () => {
    setVideoDetailsOpen(false)
    refetch()
  }

  if (isLoading) {
    return (
      <div className="content-wrapper mb-55">
        <Container spacing={1}>
          <p>Loading..</p>
        </Container>
      </div>
    )
  }

  return (
    <div className="content-wrapper mb-55">
      <Container spacing={1}>
        <Grid item lg={12}>
          <div className="is-flex aic jcsb">
            <h2 className="mt-0 mb-5 mt-20">Edit UGC project</h2>
            {isLoading ? '' : <PayButton project={project} />}
          </div>
          {isLoading || !project ? "Loading.." : <Formik
            innerRef={formikRef}
            initialValues={{
              status_id: project.status_id,
              name: project.name || '',
              goal: project.goal || '',
              owner_id: project.owner_id,
              currency: project.currency_id,
              is_enterprise: project.is_enterprise,
              enterprise_fees: project.enterprise_fees || null,
              payment_method: project.payment_method_id,
              account_manager_id: project.account_manager_id,
              ...deconstructBriefValues(project.brief),
            }}
            validationSchema={Yup.object().shape({
              status_id: Yup.number().required("Please select a valid project status"),
              name: Yup.string().required("Please enter title for this project"),
              goal: Yup.string().required("Please enter description for this project"),
              owner_id: Yup.number().required("Please select an owner for this project"),
              payment_method: Yup.string().required("Please specify a payment method for this project"),
              currency: Yup.string().required("Please specify a currency for this project"),
              enterprise_fees: Yup.number().nullable().when('is_enterprise', {
                is: true,
                then: Yup.number().min(1).required("Please set the enterprise fees")
              }),
            })}
            onSubmit={(values, { setSubmitting, errors }) => {
              const data = {
                status_id: values.status_id,
                name: values.name,
                goal: values.goal,
                account_manager_id: values.account_manager_id,
                is_enterprise: values.is_enterprise,
                enterprise_fees: values.enterprise_fees,
              }

              // if (values.status_id === Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING) && project.status_id !== Number(process.env.REACT_APP_PROJECT_STATUS_ONGOING)) {
              if (project.status_id === Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT)) {
                if (values.status_id !== Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT)) {
                  data.newProjectServices = videos.filter(e => e.expert_id).reduce((a, c) => {
                    console.log(c)
                    const expert = a.find(e => e.expert_id === c.expert_id)
                    if (!expert) {
                      a.push({
                        expert_id: c.expert_id,
                        service_id: project.service_id,
                        duration: 1,
                        cost: 0,
                        payout: 0,
                        hours: 0,
                        revisions: null,
                      })
                    }
                    return a
                  }, [])
                }
              } else if (values.status_id !== Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT)) {
                data.newProjectServices = videos.filter(e => e.expert_id).reduce((a, c) => {
                  console.log(c)
                  const expert = a.find(e => e.expert_id === c.expert_id)
                  if (!expert) {
                    a.push({
                      expert_id: c.expert_id,
                      service_id: project.service_id,
                      duration: 1,
                      cost: 0,
                      payout: 0,
                      hours: 0,
                      revisions: null,
                    })
                  }
                  return a
                }, [])
              }

              data.brief = constcutBriefJSON(values)

              data.projectVideos = videos.map((v, i) => ({
                ...v,
                cost: calculatePrice(v, false),
                payout: calculatePayout(v, i, videos, false),
              }))
              data.price = data.projectVideos.reduce((a, c) => a + (c.cost || 0), 0) + Number(data.is_enterprise ? data.enterprise_fees : 0)

              console.log(data)
              mutation.mutate(data)
              setSubmitting(false)
            }}
          >
            {
              props => {
                const {
                  values,
                  touched,
                  setFieldValue,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props

                return (
                  <form className="is-flex jcsb fdc" onSubmit={handleSubmit}>
                    <div>
                      <div className="is-flex aic mt-0 mb-10">
                        <h4 className="mt-15 mr-15 dimmed">PROJECT INFORMATION</h4>
                      </div>

                      {/* Project Status */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project status</label>
                            <Select
                              options={projectStatusOptions?.map(s => {
                                if (project.payment_method_id == process.env.REACT_APP_PAYMENT_METHOD_BANK_TRANSFER
                                  && s.value == process.env.REACT_APP_PROJECT_STATUS_FINISHED) {
                                  s.isDisabled = true
                                }
                                return s
                              })}
                              isLoading={projectStatusLoading}
                              name="status_id"
                              onChange={({ value }) => setFieldValue("status_id", value)}
                              value={projectStatusOptions?.find(s => {
                                return s.value === values.status_id
                              })}
                            />
                            {errors.status_id && touched.status_id ? (
                              <span className="v-error">{errors.status_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Title */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="name">Project title</label>
                            <input type="text" name="name" value={values.name} onChange={handleChange} className="stllr-input" placeholder="Enter project title" />
                            {errors.name && touched.name ? (
                              <span className="v-error">{errors.name}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Description */}
                      <div className="group">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <label htmlFor="goal">Project Description</label>
                            <Editor
                              apiKey={process.env.REACT_APP_TINY_API_KEY}
                              init={{
                                width: '100%',
                                height: 200,
                                menubar: false,
                                placeholder: 'Enter project description',
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount', 'textcolor'
                                ],
                                toolbar: ' bullist numlist ',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                              }}
                              onEditorChange={value => setFieldValue('goal', value)}
                              value={values.goal}
                            />
                            {/* <textarea
                              name="goal"
                              value={values.goal}
                              onChange={handleChange}
                              className="stllr-input"
                              placeholder="Enter project description"
                            ></textarea> */}
                            {errors.goal && touched.goal ? (
                              <span className="v-error">{errors.goal}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Project Project Owner */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="owner_id">Project owner</label>
                            <Select
                              options={customers}
                              isLoading={isCustomersLoading}
                              value={customers && customers.find(c => c.value === values.owner_id)}
                              name="owner_id"
                              id="owner_id"
                              className="has-full-width"
                              // onChange={(option) => setFieldValue("owner_id", option.value)}
                              isDisabled
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 40,
                                  minHeight: 40
                                })
                              }}
                            />
                            {errors.owner_id && touched.owner_id ? (
                              <span className="v-error">{errors.owner_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label>
                              <input
                                type="checkbox"
                                name="is_enterprise"
                                disabled={project.status_id != process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT || values.status_id != process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT}
                                checked={values.is_enterprise}
                                onChange={e => setFieldValue('is_enterprise', e.target.checked)
                                }
                              />{' '}
                              Is this an Enterprise project?
                            </label>
                          </Grid>

                          <Grid item lg={4}>
                            <label htmlFor="name">Extra enterprise fees</label>
                            <input
                              type="number"
                              name="enterprise_fees"
                              value={values.enterprise_fees}
                              disabled={!values.is_enterprise || project.status_id != process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT || values.status_id != process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT}
                              onChange={e => setFieldValue('enterprise_fees', e.target.value)
                              }
                              className="stllr-input mb-10"
                              placeholder="Enter extra fees"
                            />
                            {errors.enterprise_fees && touched.enterprise_fees ? (
                              <span className="v-error">{errors.enterprise_fees}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      <div className="sep mb-18" />

                      {/* Project payment method/currency */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Payment Method</label>
                            <Select
                              name='payment_method'
                              options={paymentMethods}
                              value={paymentMethods.find(p => Number(p.value) === values.payment_method)}
                              placeholder="Select payment method"
                              // onChange={({ value }) => setFieldValue('payment_method', value)}
                              isDisabled
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                            {errors.payment_method && touched.payment_method ? (
                              <span className="v-error">{errors.payment_method}</span>
                            ) : null}
                          </Grid>

                          <Grid item lg={3}>
                            <label htmlFor="name">Project Currency</label>
                            <Select
                              name='currency'
                              options={currencyOptions}
                              value={currencyOptions?.find?.(e => e.value === 3)}
                              isLoading={isCurrenciesLoading}
                              isDisabled={true}
                              placeholder="Select currency"
                              // onChange={({ value }) => {
                              //   setFieldValue('currency', value)
                              //   setCurrentCurrency(currencies.find(e => e.id === value))
                              // }}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 42,
                                  minHeight: 42
                                })
                              }}
                            />
                            {errors.currency ? (
                              <span className="v-error">{errors.currency}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                      {/* Account Manager */}
                      <div className="group">
                        <Grid container direction="row" spacing={2}>
                          <Grid item lg={3}>
                            <label htmlFor="name">Account Manager</label>
                            <Select
                              options={customers}
                              value={customers && customers.find(c => c.value === values.account_manager_id)}
                              name="account_manager_id"
                              onChange={({ value }) => setFieldValue("account_manager_id", value)}
                            />
                            {errors.account_manager_id && touched.account_manager_id ? (
                              <span className="v-error">{errors.account_manager_id}</span>
                            ) : null}
                          </Grid>
                        </Grid>
                      </div>

                    </div>

                    <div className="sep mb-18" />
                    <div className="is-flex aic mt-0 mb-10">
                      <h4 className="mt-15 mr-15 dimmed">BRIEF DETAILS</h4>
                    </div>

                    <div className="group">
                      <Grid container direction="row" spacing={2}>
                        {/* <Grid item lg={3}>
                          <Select
                            name='platform'
                            value={platforms.find(e => e.value === values.platform)}
                            options={platforms}
                            placeholder="Select platform"
                            onChange={({ value }) => setFieldValue('platform', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        <Grid item lg={3}>
                          <Select
                            name='niche'
                            value={niches.find(e => e.value === values.niche)}
                            options={niches}
                            placeholder="Select niche"
                            onChange={({ value }) => setFieldValue('niche', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        <Grid item lg={3}>
                          <Select
                            name='product_delivery'
                            value={delivering.find(e => e.value === values.product_delivery)}
                            options={delivering}
                            placeholder="Select product delivery"
                            onChange={({ value }) => setFieldValue('product_delivery', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        {/* <Grid item lg={3}>
                          <Select
                            name='tone'
                            value={tones.find(e => e.value === values.tone)}
                            options={tones}
                            placeholder="Select tone of voice"
                            onChange={({ value }) => setFieldValue('tone', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        <Grid item lg={3}>
                          <Select
                            name='country'
                            value={countries.find(e => e.value === values.country)}
                            options={countries}
                            placeholder="Select country"
                            onChange={({ value }) => setFieldValue('country', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid>
                        {/* <Grid item lg={3}>
                          <Select
                            name='language'
                            value={languages.find(e => e.value === values.language)}
                            options={languages}
                            placeholder="Select language"
                            onChange={({ value }) => setFieldValue('language', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={3}>
                          <Select
                            name='content_type'
                            value={contentTypes.find(e => e.value === values.content_type)}
                            options={contentTypes}
                            placeholder="Select content type"
                            onChange={({ value }) => setFieldValue('content_type', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={3}>
                          <Select
                            name='video_format'
                            value={formats.find(e => e.value === values.video_format)}
                            options={formats}
                            placeholder="Select video format"
                            onChange={({ value }) => setFieldValue('video_format', value)}
                            isDisabled={videos.some(video => video.expert_id !== null)}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 42,
                                minHeight: 42
                              })
                            }}
                          />
                        </Grid> */}
                        {/* <Grid item lg={12}>
                          <textarea
                            name="instructions"
                            value={values.instructions}
                            onChange={handleChange}
                            className="stllr-input"
                            placeholder="Enter instructions for creators"
                            disabled={videos.some(video => video.expert_id !== null)}
                          ></textarea>
                        </Grid>
                        <Grid item lg={12}>
                          <input
                            type="text"
                            name="reference"
                            value={values.reference}
                            onChange={handleChange}
                            className="stllr-input"
                            placeholder="Enter reference url"
                            disabled={videos.some(video => video.expert_id !== null)}
                          />
                        </Grid> */}
                      </Grid>
                    </div>

                    <input
                      type="submit"
                      className="stllr-btn primary btn-lg"
                      disabled={mutation.isLoading}
                      value="Update project"
                    />
                    {Object.keys(errors).length ? <span className="mt-10 v-error">Please check for wrong/missing fields [{Object.keys(errors).join(', ')}]</span> : ``}
                  </form>
                )
              }
            }
          </Formik>}
        </Grid>

        <div className="sep mb-18" />

        <div className="is-flex aic mt-0 mb-10">
          <h4 className="mt-15 mr-15 dimmed">Project Videos</h4>
        </div>
        {project?.status_id === Number(process.env.REACT_APP_PROJECT_STATUS_AWAITING_PAYMENT) ? <p className="mb-30">
          Creators won't get access to the project until it's paid (or ongoing)
        </p> : null}

        {/* Project Videos */}
        <Grid container direction="row" spacing={2}>
          <Grid item lg={3}>
            <label htmlFor="name">Creator</label>
          </Grid>
          <Grid item lg={2}>
            <label htmlFor="name">Video Type</label>
          </Grid>
          <Grid item lg={2}>
            Additional Attributes
          </Grid>
          <Grid item lg={1}>
            <label htmlFor="name">Payout</label>
          </Grid>
          <Grid item lg={2}>
            <label htmlFor="name">Price</label>
          </Grid>
          <Grid item lg={2}>
            <label htmlFor="name">Actions</label>
          </Grid>
        </Grid>
        <hr />

        {isVideosLoading ? "Loading.." : videos && videos.length > 0 ? videos.map((video, index) => {
          const fieldName = (name) => `videos.${index}.${name}`
          const isSponsored = video?.type_id === 2 // second video type is customized (static line)
          const isCustom = video?.type_id === 3 // third video type is customized (static line)

          return (<>
            <Grid container direction="row" spacing={2}>
              <Grid item lg={3}>
                {video.expert_id || editMode === video.id ? <Select
                  options={expertOptions}
                  isLoading={isExpertsLoading}
                  name={`${fieldName('expert_id')}`}
                  placeholder="Not assigned"
                  isDisabled={editMode !== video.id}
                  onChange={({ value }) => handleOnAssign(video.id, value)}
                  value={expertOptions.find(e => e.value === video.expert_id)}
                /> : 'Not Assigned'}
              </Grid>
              <Grid item lg={2}>
                <Select
                  options={typeOptions}
                  isLoading={isVideoTypesLoading}
                  name={`${fieldName('type_id')}`}
                  placeholder="Select video type"
                  isDisabled
                  value={typeOptions.find(e => e.value === video.type_id)}
                />
              </Grid>
              <Grid item lg={2}>
                {!(project.status_id === 2 || project.status_id === 4) || video.is_submitted !== 0 ?
                  <button
                    type="button"
                    className="stllr-btn primary btn-sm"
                    style={{ padding: "0px 8px" }}
                    onClick={() => setViewVideoDetailsOpen(video)}
                  >
                    View video data
                  </button>
                  :
                  <button
                    type="button"
                    className="stllr-btn primary btn-sm"
                    style={{ padding: "0px 8px" }}
                    disabled={!(project.status_id === 2 || project.status_id === 4) || video.is_submitted !== 0}
                    onClick={() => setVideoDetailsOpen(video)}
                  >
                    Fill video data
                  </button>
                }

                 {/* <button
                    type="button"
                    className="stllr-btn primary btn-sm"
                    style={{ padding: "0px 8px" }}
                    disabled={!(project.status_id === 2 || project.status_id === 4) || video.is_submitted !== 0}
                    onClick={() => setVideoDetailsOpen(video)}
                  >
                    Fill video data
                  </button> */}
                {/* <Select
                  name={`${fieldName('content_type')}`}
                  options={contentTypes}
                  placeholder="Content type"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={contentTypes.find(e => e.value === video.content_type)}
                  onChange={({ value }) => setFieldValue(fieldName('content_type'), value)}
                  className='mb-10'
                />
                <Select
                  options={tones}
                  name={`${fieldName('tone')}`}
                  placeholder="Tone of voice"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={tones.find(e => e.value === video.tone)}
                  onChange={({ value }) => setFieldValue(fieldName('tone'), value)}
                  className='mb-10'
                />
                <Select
                  name={`${fieldName('language')}`}
                  options={languages}
                  placeholder="Language"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={languages.find(e => e.value === video.language)}
                  onChange={({ value }) => setFieldValue(fieldName('language'), value)}
                  className='mb-10'
                />
                <Select
                  name={`${fieldName('platform')}`}
                  options={platforms}
                  placeholder="Platform"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={platforms.find(e => e.value === video.platform)}
                  onChange={({ value }) => setFieldValue(fieldName('platform'), value)}
                  className='mb-10'
                />
                <Select
                  options={durations}
                  name={`${fieldName('duration')}`}
                  placeholder="Video duration"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={durations.find(e => e.value === video.duration)}
                  onChange={({ value }) => setFieldValue(fieldName('duration'), value)}
                  className='mb-10'
                />
                <Select
                  name={`${fieldName('video_format')}`}
                  options={formats}
                  placeholder="Video format"
                  isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                  value={formats.find(e => e.value === video.video_format)}
                  onChange={({ value }) => setFieldValue(fieldName('video_format'), value)}
                  className='mb-10'
                />
                {isCustom ? (<div>
                  <Select
                    options={genders}
                    name={`${fieldName('gender')}`}
                    placeholder="Select gender"
                    isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                    value={genders.find(e => e.value === video.gender)}
                    className='mb-10'
                  />
                  <Select
                    options={ages}
                    name={`${fieldName('age')}`}
                    placeholder="Select age"
                    isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                    value={ages.find(e => JSON.stringify(e.value).replace(',', ', ') === video.age)}
                    className='mb-10'
                  />
                  <Select
                    options={places}
                    name={`${fieldName('place')}`}
                    placeholder="Select place"
                    isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                    value={places.find(e => e.value === video.place)}
                  />
                </div>) : isSponsored ? (<div>
                  <Select
                    options={genders}
                    name={`${fieldName('gender')}`}
                    placeholder="Select gender"
                    isDisabled={(project.status_id !== 4) && (project.status_id !== 2)}
                    value={genders.find(e => e.value === video.gender)}
                  />
                </div>) : null} */}
              </Grid>
              <Grid item lg={1}>
                {/* {calculatePayout(video, index, videos)} */}
                {video.earning_formatted}
              </Grid>
              <Grid item lg={2}>
                {/* {calculatePrice(video)} */}
                {video.price_formatted}
              </Grid>
              <Grid item lg={2}>
                {video.expert_id === null ? (
                  editMode === video.id
                    ? <button
                      type="button"
                      className="stllr-btn btn-sm"
                      style={{ padding: "0px 8px" }}
                      onClick={() => setEditMode(null)}
                    >
                      CANCEL
                    </button>
                    :
                    <div className="is-flex aic">
                      <button
                        type="button"
                        className="stllr-btn btn-sm primary mr-10"
                        style={{ padding: "0px 8px" }}
                        onClick={() => setEditMode(video.id)}
                        disabled={isAssigning || !video.is_submitted}
                      >
                        ASSIGN
                      </button>
                      <button
                        type="button"
                        className="stllr-btn primary btn-sm"
                        style={{ padding: "0px 8px" }}
                        disabled={!(project.status_id === 2 || project.status_id === 4) || video.is_submitted !== 1}
                        onClick={() => setVideoRecoOpen(video)}
                      >
                        Recommendations
                      </button>
                    </div>
                ) : (
                  <button
                    type="button"
                    className="stllr-btn btn-sm danger"
                    style={{ padding: "0px 8px" }}
                    onClick={() => handleOnUnassign(video.id, video)}
                    disabled={isUnassigning || video.is_approved}
                  >
                    UN-ASSIGN
                  </button>
                )}
              </Grid>
              <VideoDetails video={video} />
            </Grid>
            <hr />
          </>)
        }) : <div className="is-flex jcc">no videos added</div>}


        <div className="sep mb-18" />

        {/* <div className="is-flex aic mt-0 mb-10">
          <h4 className="mt-15 mr-15 dimmed">Brief Recommendations</h4>
        </div>

        {isLoading ? "Loading.." : <BriefRecommendations project={project} videos={videos} expertOptions={expertOptions} />} */}
        <VideoRecommendationsModal isOpen={videoRecoOpen} onClose={() => setVideoRecoOpen(null)} video={videoRecoOpen} expertOptions={expertOptions} />
        <FillVideoDetails
          isOpen={videoDetailsOpen}
          onClose={() => setVideoDetailsOpen(null)}
          onSubmit={handleOnVideoFormSubmit}
          video={videoDetailsOpen}
          contentTypes={contentTypes}
          tones={tones}
          languages={languages}
          platforms={platforms}
          durations={durations}
          formats={formats}
          genders={genders}
          ages={ages}
        />

        <ViewVideoDetails
          isOpen={viewVideoDetailsOpen}
          onClose={() => setViewVideoDetailsOpen(null)}
          video={viewVideoDetailsOpen}
          platforms={platforms}
          genders={genders}
        />
      </Container>
    </div>
  )
}

export default EditUGCProject

const VideoDetails = ({ video }) => {
  const [open, setOpen] = useState(false);

  return !video.expert_id ? null : (
    <React.Fragment>
      <Grid item xs={12}><a className="" onClick={() => setOpen(v => !v)}>{open ? 'hide details' : 'show details'}</a></Grid>
      {open ? (
        <React.Fragment>
          <Grid item xs={12}>
            {video.script ? (<div>
              script uploaded #{video.script.number}:
              <br />
              <p>{video.script.content}</p>
              script request changes: {video.script.requested_changes || 'none'}
              <br />
              video status: {video.script.is_approved ? 'approved' : 'pending approval'}
            </div>) : 'no script'}
          </Grid>
          <Grid item xs={12}>
            {video.file ? <div>
              video uploaded #{video.file.number}: <a href={video.file.url}>{video.file.url}</a>
              <br />
              video request changes: {video.file.requested_changes || 'none'}
              <br />
              video status: {video.file.is_approved ? 'approved' : 'pending approval'}
            </div> : 'no video'}
          </Grid>
          <Grid item xs={12}>
            {video.is_approved ? (<div>
              is reviewed: {video.is_reviewed ? 'Yes' : 'No'}
            </div>) : null}
          </Grid>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}